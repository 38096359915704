import Vue from 'vue';

export default {
  get: async () => Vue.http.get('general/cache')
    .then((res) => res.json()),

  delete: async (keys) => Vue.http.post('general/cache', keys)
    .then((res) => res.json()),

  deleteAll: async () => Vue.http.delete('general/cache')
    .then((res) => res.json()),
};
