<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          Cache
        </h3>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col lg="8" class="mb-sm-2">
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="$t('inputs.typeSearch')"
          />

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              {{ $t('buttons.clear') }}
            </b-button>
          </b-input-group-append>

          <p class="pl-2 mb-0 pt-2">{{ $t('generalCache.registers', { num: totalRows }) }}</p>
        </b-input-group>
      </b-col>

      <b-col lg="4" sm="12" class="text-right">
        <b-button variant="success" class="mr-2" @click="onLoad">
          {{ $t('buttons.refresh') }}
        </b-button>

        <b-button variant="danger" @click="deleteModal = true">
          {{ $t('buttons.removeAll') }}
        </b-button>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :busy="loading"
      :items="data"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
      :emptyFilteredText="$t('messages.emptyFilteredTable')"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2 align-items-center d-flex flex-column">
          <b-spinner class="align-middle flex-direction-column mb-3"></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <p class="text-center m-0">
          <b-button
            size="sm"
            variant="danger"
            @click="removeItem(data.item.key)"
          >
            <b-icon icon="trash" />
            {{ $t('buttons.delete') }}
          </b-button>
        </p>
      </template>
    </b-table>

    <b-row class="text-center mt-4" v-if="totalRows > perPage">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        />
      </b-col>
    </b-row>

    <b-modal
      centered
      v-model="deleteModal"
      @ok="removeAll"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">{{ $t('messages.deleteConfirmMessage') }}</div>
    </b-modal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import GeneralCache from '../../../../services/GeneralCache';
import Toast from '../../../../assets/js/toast';

export default {
  data() {
    const data = {
      list: [],
      filter: '',

      totalRows: 0,
      loading: true,
      deleteModal: false,
      currentPage: 1,
      perPage: 50,

      fields: [
        { key: 'key', label: 'Key', sortable: true },
        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.loading = true;

        const res = await GeneralCache.get();

        this.list = res
          .sort()
          .map((key) => ({ key }));
        this.totalRows = this.list.length;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },

    async removeItem(key) {
      try {
        this.loading = true;

        await GeneralCache.delete([key]);

        Toast.success(this, 'messages.removeSuccess');
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('key', key);
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.onLoad();
      }
    },

    async removeAll() {
      try {
        this.loading = true;

        await GeneralCache.deleteAll();
      } catch (e) {
        Sentry.captureException(e);

        Toast.error(this, e);
      } finally {
        this.onLoad();
      }
    },

    onFiltered() {
      this.currentPage = 1;
    },
  },

  computed: {
    data() {
      return this.list;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
